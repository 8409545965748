import { motion } from "framer-motion";
import React, { useMemo, useState, useEffect } from "react";
import StackRow from "../components/layoutUI/StackRow";
import Headline2 from "../components/typographyUI/Headline2";
import Text from "../components/typographyUI/Text";
import { Add } from "@mui/icons-material";
import CustomButton from "../components/formUI/CustomButton";
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  Stack,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import CustomTextFieldFormik from "../components/formUI/CustomTextFieldFormik";
import CustomDialog from "../components/layoutUI/CustomDialog";
import PageHeader from "../components/pageUI/PageHeader";
import { Close } from "reactour";
import { useSelector } from "react-redux";
import axios from "axios";
import Headline1 from "../components/typographyUI/Headline1";
import CustomDataGrid from "../components/pageUI/CustomDataGrid";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Typography } from "@mui/material";
import dayjs from "dayjs";
import { TimePicker } from "@mui/x-date-pickers";
import { useGetApis } from "../hooks/useGetApis";

const GreenLabel = ({ text }) => (
  <Text
    style={{
      backgroundColor: "#F0FDF4",
      color: "green",
      borderRadius: "5px",
      padding: "0.5rem",
    }}
  >
    {text}
  </Text>
);

const daysOfWeek = [
  { name: "Sunday", id: 7 },
  { name: "Monday", id: 1 },
  { name: "Tuesday", id: 2 },
  { name: "Wednesday", id: 3 },
  { name: "Thursday", id: 4 },
  { name: "Friday", id: 5 },
  { name: "Saturday", id: 6 },
];

const weeks = ["1", "2", "3", "4", "5"];

const WeekCheckbox = ({ week, values, setFieldValue }) => (
  <FormControlLabel
    control={
      <Checkbox
        checked={values.selectedWeeks.includes(week)}
        onChange={(event) => {
          if (event.target.checked) {
            setFieldValue("selectedWeeks", [...values.selectedWeeks, week]);
          } else {
            setFieldValue(
              "selectedWeeks",
              values.selectedWeeks.filter((w) => w !== week)
            );
          }
        }}
        name={week}
      />
    }
    label={`Week ${week.charAt(week.length - 1)}`}
  />
);

const convertToIST = (dateString) => {
  const date = new Date(dateString);
  const istOffset = 5.5 * 60 * 60 * 1000; // IST offset is UTC+5:30

  // Adjust the time zone offset for IST
  const istTime = new Date(date.getTime() + istOffset);

  // Format to ISO string or any other desired format
  const istTimeString = istTime.toISOString();

  return istTimeString;
};

const MessMenu = () => {
  const [open, setOpen] = useState(false);
  const outletIdNew = useSelector((state) => state.outledId.outletId);
  const [selectedWeek, setSelectedWeek] = useState("1");
  const [loading, setLoading] = useState(false);

  const {
    setMealTime,
    getWeeklyMenu,
    deleteMessMenu,
    addWeeklyMenu,
    getMealTime,
  } = useGetApis();

  const [mealTimes, setMealTimes] = useState({
    breakfast: {
      startTime: null,
      endTime: null,
      rsvpDeadline: null,
      enabled: true,
    },
    lunch: {
      startTime: null,
      endTime: null,
      rsvpDeadline: null,
      enabled: true,
    },

    hightea: {
      startTime: null,
      endTime: null,
      rsvpDeadline: null,
      enabled: true,
    },
    dinner: {
      startTime: null,
      endTime: null,
      rsvpDeadline: null,
      enabled: true,
    },
  });

  const handleCheckboxChange = (meal, isChecked) => {
    setMealTimes((prevMealTimes) => ({
      ...prevMealTimes,
      [meal]: {
        ...prevMealTimes[meal],
        enabled: isChecked,
      },
    }));
  };

  console.log("mealtime ", mealTimes);

  useEffect(() => {
    // Example API call to fetch meal times
    outletIdNew &&
      fetchMealTimesFromApi(outletIdNew, getMealTime)
        .then((data) => {
          setMealTimes(data); // Assuming data is structured similar to state above
        })
        .catch((error) => {
          console.error("Error fetching meal times:", error);
        });
  }, [outletIdNew]);

  const handleTimeChange = (meal, timeType, newValue) => {
    console.log("new value ", newValue);

    setMealTimes((prevMealTimes) => ({
      ...prevMealTimes,
      [meal]: {
        ...prevMealTimes[meal],
        [timeType]: newValue,
      },
    }));
  };

  const handleSubmit = async () => {
    const formattedMealTimes = Object.fromEntries(
      Object.entries(mealTimes).map(([meal, times]) => [
        meal,
        {
          startTime: convertToIST(times.startTime.toISOString()),
          endTime: convertToIST(times.endTime.toISOString()),
          rsvpDeadline: convertToIST(times.rsvpDeadline.toISOString()),
        },
      ])
    );
    console.log("Converted Times:", {
      ...formattedMealTimes,
      outletId: outletIdNew,
    });
    // Submit the convertedTimes as needed
    setLoading(true);

    try {
      const settime = await axios.post(`${setMealTime}`, {
        ...mealTimes,
        outletId: outletIdNew,
      });
      console.log("set meal time res ", settime);
      if (settime?.data?.success) {
        toast.success("Meal Time Updated Successfully");
      }
    } catch (error) {
      console.log("Error while time set api called...");
    } finally {
      setLoading(false);
    }
  };

  const handleWeekChange = (week) => {
    setSelectedWeek(week);
  };

  const navigate = useNavigate();

  const columns1 = useMemo(
    () => [
      {
        field: "day",
        headerName: "Day",
        flex: 1,
        maxWidth: 100,
        renderCell: ({ row }) => (
          <Text style={{ paddingLeft: "0.5rem" }} bold>
            {daysOfWeek[row?.dayOfWeek == 7 ? 0 : row?.dayOfWeek]?.name}
          </Text>
        ),
      },
      {
        field: "brakfast",
        headerName: "Breakfast",
        flex: 1,
        minWidth: 150,
        renderCell: ({ row }) => (
          <Text style={{ paddingLeft: "0.5rem" }} bold>
            {row?.breakfast}
          </Text>
        ),
      },
      {
        field: "lunch",
        headerName: "Lunch",
        flex: 1,
        minWidth: 150,
        renderCell: ({ row }) => (
          <Text style={{ paddingLeft: "0.5rem" }} bold>
            {row?.lunch}
          </Text>
        ),
      },

      {
        field: "hightea",
        headerName: "High Tea",
        flex: 1,
        minWidth: 150,
        sortable: false,
        renderCell: ({ row }) => <Text bold>{row?.highTea}</Text>,
      },
      {
        field: "dinner",
        headerName: "Dinner",
        flex: 1,
        minWidth: 150,
        renderCell: ({ row }) => <Text bold> {row?.dinner}</Text>,
      },
    ],
    []
  );

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <StackRow>
        <div>
          <Headline1>Menu</Headline1>
          <Text style={{ marginBottom: "1.2rem", fontSize: "1.3rem" }}>
            View and update your mess menu
          </Text>
        </div>
        <GreenLabel text={"New"} />
      </StackRow>
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "1rem",
          marginTop: "2rem",
        }}
      >
        <div>
          {weeks.map((week) => (
            <CustomButton
              key={week}
              variant={selectedWeek === week ? "contained" : "outlined"}
              onClick={() => handleWeekChange(week)}
              style={{ margin: "0 0.3rem", height: "2.75rem" }}
            >
              Week {week}
            </CustomButton>
          ))}
        </div>
        <CustomButton startIcon={<Add />} onClick={() => setOpen(true)}>
          Add Menu
        </CustomButton>
      </Box>

      {outletIdNew && (
        <CustomDataGrid
          rowId="menuId1"
          name="menu-table"
          labelName="MessMenu"
          height={"450px"}
          columns={columns1}
          url={`${getWeeklyMenu}` + `${outletIdNew}?weekNumber=${selectedWeek}`}
          noEditAction
          menuOnClick={({ id, label }, row) => {
            if (label === "Edit") {
              navigate(
                `/mess/menu/${row?.menuId1}/${row?.menuId2}/${row?.menuId3}/${row?.menuId4}`
              );
            }
          }}
          deleteOption
          deleteUrl={`${deleteMessMenu}`}
          deleteItem
          // filterFn={getFilterData}
          getRowHeight={() => "auto"}
        />
      )}

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box>
          <Box>
            <Headline1>Meal Time</Headline1>
          </Box>
          {Object.keys(mealTimes).map((meal) => (
            <Box
              style={{ display: "flex", flexDirection: "column" }}
              key={meal}
            >
              <Box sx={{ display: "flex", alignItems: "center", mb: 2, mt: 4 }}>
                {/* Checkbox added before meal label */}
                <Checkbox
                  checked={mealTimes[meal].enabled}
                  onChange={(e) => handleCheckboxChange(meal, e.target.checked)}
                />
                <Typography fontSize={"1.3rem"} fontWeight={450}>
                  {meal}
                </Typography>
                <Box sx={{ flexGrow: 1 }} />
              </Box>

              {/* TimePicker components, only shown if the checkbox is checked */}
              {mealTimes[meal].enabled && (
                <Box
                  style={{ display: "flex", flexDirection: "row", gap: "1rem" }}
                >
                  <Box>
                    <TimePicker
                      label="Start Time"
                      value={mealTimes[meal].startTime}
                      onChange={(newValue) =>
                        handleTimeChange(meal, "startTime", newValue)
                      }
                      renderInput={(params) => <TextField {...params} />}
                      fullWidth
                    />
                  </Box>
                  <Box>
                    <TimePicker
                      label="End Time"
                      value={mealTimes[meal].endTime}
                      onChange={(newValue) =>
                        handleTimeChange(meal, "endTime", newValue)
                      }
                      renderInput={(params) => <TextField {...params} />}
                      fullWidth
                    />
                  </Box>
                  <Box>
                    <TimePicker
                      label="RSVP Deadline"
                      value={mealTimes[meal].rsvpDeadline}
                      onChange={(newValue) =>
                        handleTimeChange(meal, "rsvpDeadline", newValue)
                      }
                      renderInput={(params) => <TextField {...params} />}
                      fullWidth
                    />
                  </Box>
                </Box>
              )}
            </Box>
          ))}

          {/* Save button */}
          <Box
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "2rem",
              paddingRight: "42rem",
            }}
          >
            <CustomButton
              variant="contained"
              color="primary"
              onClick={handleSubmit}
            >
              {loading ? "Saving..." : "Save"}
            </CustomButton>
          </Box>
        </Box>
      </LocalizationProvider>

      {open && (
        <CustomDialog open={true} sx={{ width: "80rem" }}>
          <Box style={{ display: "flex", alignItems: "center" }}>
            <Headline2>Add Menu Item</Headline2>
            <Close
              style={{ cursor: "pointer", marginTop: "1rem" }}
              onClick={() => setOpen(false)}
            />
          </Box>

          <Formik
            initialValues={{
              dayOfWeek: "",
              breakfast: "",
              lunch: "",
              dinner: "",
              highTea: "",
              selectedWeeks: [],
              outletId: outletIdNew,
            }}
            validate={(values) => {
              const errors = {};
              if (!values.dayOfWeek) {
                errors.dayOfWeek = "Day of Week is required";
              }
              if (!values.breakfast) {
                errors.breakfast = "Breakfast is required";
              }
              if (!values.lunch) {
                errors.lunch = "Lunch is required";
              }
              if (!values.dinner) {
                errors.dinner = "Dinner is required";
              }
              if (!values.highTea) {
                errors.highTea = "High Tea is required";
              }
              if (values.selectedWeeks.length === 0) {
                errors.selectedWeeks = "At least one week must be selected";
              }
              return errors;
            }}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              console.log("menu ", values);
              setSubmitting(true);
              try {
                const result = await axios.post(`${addWeeklyMenu}`, values);
                console.log("menu ", result);

                if (result?.data?.success) {
                  setSubmitting(false);
                  resetForm();
                  setOpen(false);
                  window.location.reload();
                  toast.success("Menu Added Successfully");
                }
              } catch {
                console.log("Error while creating menu");
              }
            }}
          >
            {({ isSubmitting, setFieldValue, values }) => (
              <Form>
                <Stack
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                    marginTop: "16px",
                  }}
                >
                  <FormControl fullWidth required>
                    <InputLabel id="dayOfWeek-label">Day of Week</InputLabel>
                    <Select
                      labelId="dayOfWeek-label"
                      id="dayOfWeek"
                      name="dayOfWeek"
                      value={values.dayOfWeek}
                      onChange={(event) =>
                        setFieldValue("dayOfWeek", event.target.value)
                      }
                      label="Day of Week"
                    >
                      {daysOfWeek.map((day) => (
                        <MenuItem key={day.id} value={day.id}>
                          {day.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <CustomTextFieldFormik
                    label="Breakfast"
                    placeholder="Enter Breakfast"
                    required
                    name="breakfast"
                  />
                  <CustomTextFieldFormik
                    label="Lunch"
                    placeholder="Enter Lunch"
                    required
                    name="lunch"
                  />
                  <CustomTextFieldFormik
                    label="High Tea"
                    placeholder="Enter High Tea"
                    required
                    name="highTea"
                  />
                  <CustomTextFieldFormik
                    label="Dinner"
                    placeholder="Enter Dinner"
                    required
                    name="dinner"
                  />

                  <Box>
                    {weeks.map((week) => (
                      <WeekCheckbox
                        key={week}
                        week={week}
                        values={values}
                        setFieldValue={setFieldValue}
                      />
                    ))}
                    <ErrorMessage
                      name="selectedWeeks"
                      component="div"
                      style={{ color: "red" }}
                    />
                  </Box>

                  <Box style={{ display: "flex", justifyContent: "flex-end" }}>
                    <CustomButton
                      type="submit"
                      variant="contained"
                      color="secondary"
                      disabled={isSubmitting}
                      style={{
                        height: "35px",
                        width: "5rem",
                      }}
                    >
                      Submit
                    </CustomButton>
                  </Box>
                </Stack>
              </Form>
            )}
          </Formik>
        </CustomDialog>
      )}
    </motion.div>
  );
};

export default MessMenu;

const fetchMealTimesFromApi = async (outletIdNew, getMealTime) => {
  try {
    const getTime = await axios.get(`${getMealTime}${outletIdNew}`);
    console.log("get time res ", getTime?.data?.data);
    const timeStamp = getTime?.data?.data;

    return {
      Breakfast: {
        startTime: dayjs(timeStamp[0]?.startTime),
        endTime: dayjs(timeStamp[0]?.endTime),
        rsvpDeadline: dayjs(timeStamp[0]?.rsvpDeadline),
        enabled: timeStamp[0]?.enabled,
      },
      Lunch: {
        startTime: dayjs(timeStamp[1]?.startTime),
        endTime: dayjs(timeStamp[1]?.endTime),
        rsvpDeadline: dayjs(timeStamp[1]?.rsvpDeadline),
        enabled: timeStamp[1]?.enabled,
      },
      Hightea: {
        startTime: dayjs(timeStamp[2]?.startTime),
        endTime: dayjs(timeStamp[2]?.endTime),
        rsvpDeadline: dayjs(timeStamp[2]?.rsvpDeadline),
        enabled: timeStamp[2]?.enabled,
      },
      Dinner: {
        startTime: dayjs(timeStamp[3]?.startTime),
        endTime: dayjs(timeStamp[3]?.endTime),
        rsvpDeadline: dayjs(timeStamp[3]?.rsvpDeadline),
        enabled: timeStamp[3]?.enabled,
      },
    };
  } catch (error) {
    console.log("Error while fetch meal time");
  }
};
