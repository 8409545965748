import React, { useMemo } from "react";
import { Box, Divider, LinearProgress, Stack } from "@mui/material";
import PageHeader from "../components/pageUI/PageHeader";
import ItemCard from "../components/pageUI/ItemCard";
import PaperBox from "../components/layoutUI/PaperBox";
import Headline3 from "../components/typographyUI/Headline3";
import Text from "../components/typographyUI/Text";
import StackRow from "../components/layoutUI/StackRow";
import Title from "../components/typographyUI/Title";
import CustomSearchTextField from "../components/formUI/CustomSearchTextField";
import StatusFilter from "../components/pageUI/filters/StatusFilter";
import DateFilter from "../components/pageUI/filters/DateFilter";
import ServerPaginationDataGrid from "../components/pageUI/ServerPaginationDataGrid";
import CustomChip from "../components/pageUI/CustomChip";
import { motion } from "framer-motion";
import { useGetApis } from "../hooks/useGetApis";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";
import axios from "axios";
import { useState } from "react";
import CustomLoader from "../components/layoutUI/CustomLoader";
import DateRangePicker from "../components/pageUI/filters/DateRangePicker";
import CustomDataGrid from "../components/pageUI/CustomDataGrid";
import CustomButton from "../components/formUI/CustomButton";
import CloudPng from "../assets/icons/cloud.png";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import ExcelJS from "exceljs";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";

const Settlements = () => {
  function formatDate(date) {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;

  const firstDayOfMonth = new Date(currentYear, currentMonth - 1, 1);

  const formattedStartDate = `${currentYear}-${String(currentMonth).padStart(
    2,
    "0"
  )}-01`;
  const formattedEndDate = currentDate.toISOString().split("T")[0];

  const apiFormatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return !dateString ? "" : `${year}/${month}/${day}`;
  };

  const [startDate, setStartDate] = useState(formattedStartDate);
  const [endDate, setEndDate] = useState(formattedEndDate);

  const [dummyRows, setDummyRows] = useState([]);

  const [status, setStatus] = useState("");

  const { user } = useSelector((state) => state.auth);
  const { settlemenUrl, settlementDashboard } = useGetApis();

  const {
    data,
    isLoading,
    isFetching: isFetching,
  } = useQuery(
    [endDate, startDate],
    () => {
      if (
        endDate &&
        endDate !== "Invalid Date" &&
        startDate &&
        startDate !== "Invalid Date"
      ) {
        return axios.post(settlemenUrl, {
          outletId: user?.outletId,
          startDate: startDate,
          endDate: endDate,
        });
      }
    },
    {
      onSuccess: (res) => {
        if (res) {
          setDummyRows(res?.data?.data);
        }
        //
      },
    }
  );
  const [settlementData, setSettlementData] = useState({});
  const [settlementAmounts, setSettlementAmounts] = useState([]);
  const {
    data: _data,
    isLoading: loading1,
    isFetching: isFetching1,
  } = useQuery(
    [endDate, startDate, "settementDashboard"],
    () => {
      if (
        endDate &&
        endDate !== "Invalid Date" &&
        startDate &&
        startDate !== "Invalid Date"
      ) {
        return axios.post(settlementDashboard, {
          outletId: user?.outletId,
          startDate: startDate,
          endDate: endDate,
        });
      }
    },
    {
      onSuccess: (res) => {
        if (res) {
          let dataOriginal = { ...res.data.data[0] };
          let data = res.data.data[0];
          setSettlementData(dataOriginal);
          delete data.address;
          delete data.campus_name;
          delete data.city;
          delete data.logo;
          delete data.outlet_name;
          setSettlementAmounts(Object.entries(data));
        }
        //
      },
    }
  );

  const getFilterData = (data) => {
    let _data = data;
    if (status)
      _data = _data?.filter((row) => {
        switch (status) {
          case "Paid":
            return row.status === "paid";
          case "Failed":
            return row.status === "failed";
          case "Pending":
            return row.status === "pending";
          default:
            return true;
        }
      });
    return _data;
  };

  // const generateExcel = (_jsonData = []) => {
  //   const jsonData = _jsonData.map((d) => ({ ...d }));
  //   const generateColumnWidths = (width, count) =>
  //     Array(count).fill({ wch: width });
  //   const columnWidths = generateColumnWidths(20, 30).concat({ wch: 35 });
  //   const ws = XLSX.utils.json_to_sheet(jsonData, { skipHeader: true });
  //   ws["!cols"] = columnWidths;
  //   const wb = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(wb, ws, "Settlements");

  //   const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "buffer" });
  //   const data = new Blob([excelBuffer], {
  //     type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
  //   });
  //   saveAs(data, "Settlement Data");
  // };

  const generateExcel = (_jsonData = []) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Settlements");

    _jsonData.forEach((data) => {
      worksheet.addRow(data);
    });

    worksheet.getRow(1).eachCell({ includeEmpty: true }, (cell) => {
      cell.font = { bold: true };
    });

    worksheet.getRow(2).eachCell({ includeEmpty: true }, (cell) => {
      cell.font = { bold: true };
    });

    worksheet.columns.forEach((column) => {
      column.width = 20;
    });

    workbook.xlsx.writeBuffer().then((excelBuffer) => {
      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "Settlement Data");
    });
  };

  const [chipColor] = useState({
    paid: {
      backgroundColor: "#F0FDF4",
      textColor: "#15803D",
    },
    pending: {
      backgroundColor: "#FFFBEB",
      textColor: "#B45309",
    },
    failed: {
      backgroundColor: "#FEF2F2",
      textColor: "#B91C1C",
    },
  });

  const columns = useMemo(() => [
    {
      field: "settlement_date",
      headerName: "Settlement Date",
      flex: 1,
      minWidth: 180,
      sortable: false,
      renderCell: ({ row }) => (
        <>
          <Text bold>{formatDate(row?.settlement_date)}</Text>
        </>
      ),
    },
    {
      field: "total_order",
      headerName: "Total Order",
      flex: 1,
      minWidth: 180,
      sortable: false,
      renderCell: ({ row }) => <Text bold>{row?.total_order}</Text>,
    },
    {
      field: "deduction",
      headerName: "Deduction",
      flex: 1,
      minWidth: 180,
      sortable: false,
      renderCell: ({ row }) => <Text bold>{row?.deduction || "-"}</Text>,
    },
    {
      field: "settlement_amount",
      headerName: "Settlement Amount",
      flex: 1,
      minWidth: 180,
      sortable: false,
      renderCell: ({ row }) => <Text bold>₹ {row?.settlement_amount}</Text>,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: 180,
      sortable: false,
      renderCell: ({ row }) => (
        <CustomChip
          label={row?.status}
          textColor={chipColor[row?.status.toLowerCase()]?.textColor}
          backgroundColor={
            chipColor[row?.status?.toLowerCase()]?.backgroundColor
          }
        />
      ),
    },
  ]);
  const rows = [
    {
      id: 1,
      settlement_date: 999,
      total_order: 999,
      deduction: 999,
      settlement_amount: 999,
      status: 999,
    },
  ];
  function formatDate(inputDate) {
    var date = new Date(inputDate);

    var monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    var year = date.getFullYear();
    var month = date.getMonth();
    var day = date.getDate();

    var formattedDate = day + " " + monthNames[month] + ", " + year;

    return formattedDate;
  }
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {/* {(loading1 || isLoading || isFetching || isFetching1) && <CustomLoader />} */}

      <Box maxWidth={"100%"}>
        {/* <PageHeader   /> */}
        <StackRow between>
          <ItemCard
            sx={{ mb: "18px",display:"flex",alignItems:"center" }}
            title={settlementData?.campus_name}
            subTitle={settlementData?.address}
            img={settlementData?.logo}
          />
          <CustomButton
            textColor="#4F46E5"
            bgColor="#EEF2FF"
            variant="outlined"
            startIcon={<CloudDownloadOutlinedIcon />}
            disabled={!Boolean(startDate && endDate)}
            onClick={() => {
              axios
                .post(
                  process.env.REACT_APP_BACKEND_BASE_URL +
                    "settlement/adminSettlement/orderLevelExcelSheet",
                  {
                    start_date: apiFormatDate(startDate),
                    end_date: apiFormatDate(endDate),
                    outletId: user?.outletId,
                  }
                )
                .then((res) => {
                  generateExcel(res.data.data);
                })
                .catch((error) => {});
            }}
          >
            Generate Excel
          </CustomButton>
        </StackRow>

        <PaperBox
          // padding={"12px 18px"}
          // padding={0}
          sx={{
            mb: "18px",
            overflow: "hidden",
          }}
          boxSx={{
            padding: 0,
          }}
          shadowed
          noBorder
        >
          {(isLoading || loading1 || isFetching || isFetching1) && (
            <LinearProgress
              sx={
                {
                  // top : '-13px'
                }
              }
            />
          )}
          <StackRow gap={"24px"} padding={"12px 18px"} sx={{ height: "78px",display:"flex",alignItems:"center" }}>
            {settlementAmounts
              ?.filter(
                (f) =>
                  f[0] != "total_refund_charge" &&
                  f[0] != "receivable_through_cash"
              )
              ?.map((order, index) => (
                <React.Fragment key={index}>
                  <Stack
                    sx={{
                      flex: 1,
                    }}
                  >
                    <Headline3>₹ {order[1] || 0}</Headline3>
                    <Text>
                      {order[0]
                        .split("_")
                        .map(
                          (word) => word.charAt(0).toUpperCase() + word.slice(1)
                        )
                        .join(" ")}
                    </Text>
                  </Stack>
                  {index !== 2 && <Divider orientation="vertical" flexItem />}
                </React.Fragment>
              ))}
          </StackRow>
        </PaperBox>
        
        <StackRow
          mb={2}
          gap={"12px"}
          sx={{
            width: "100%",
            marginTop:"5rem"
          }}
        >
          {/* <CustomSearchTextField placeholder="Search" /> */}
          <Title >Settlements</Title>
          <Box ml={"auto"}>
            <StatusFilter
              label="Status"
              defaultValue={"All"}
              menuList={[
                { id: null, label: "All" },
                { id: 1, label: "Pending" },
                { id: 2, label: "Paid" },
                { id: 3, label: "Failed" },
              ]}
              onClick={(label) => setStatus(label)}
            />
          </Box>
          {/* <DateFilter
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              noDateChange={false}
            /> */}
          <DateRangePicker
            setFrom={setStartDate}
            setTo={setEndDate}
            fromDate={startDate}
            toDate={endDate}
            defaultValue
          />
        </StackRow>

        <CustomDataGrid
          name={"Settlements"}
          labelName={"Settlements data"}
          loading={loading1 || isLoading}
          height={"450px"}
          columns={columns}
          dummyRows={dummyRows}
          rowId={"settlement_amount"}
          noActions
          filterFn={getFilterData}
          noDataScreen
        />
      </Box>
    </motion.div>
  );
};

export default Settlements;
