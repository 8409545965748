import { Box, Button, Chip, Divider, IconButton } from "@mui/material";
import React, { useMemo, useState, useEffect, useRef } from "react";
import PageHeader from "../PageHeader";
import StackRow from "../../layoutUI/StackRow";
import CustomSearchTextField from "../../formUI/CustomSearchTextField";
import DateFilter from "../filters/DateFilter";
import CustomFilter from "../filters/CustomFilter";
import StatusFilter from "../filters/StatusFilter";
import CustomDataGrid from "../CustomDataGrid";
import ItemCard from "../ItemCard";
import CustomSwitch from "../../formUI/CustomSwitch";
import Text from "../../typographyUI/Text";
import CustomChip from "../CustomChip";
import { useDispatch, useSelector } from "react-redux";
import { useGetApis } from "../../../hooks/useGetApis";
import { Stack } from "@mui/system";
import moment from "moment";
import ButtonPng from "../../../assets/icons/Button.png";
import CloseButtonPng from "../../../assets/icons/CloseButton.png";
import { acceptOrder, rejectOrder } from "../../../Services/orderService";
import { OrderStatus } from "./OrderStatus";
import { useNavigate } from "react-router-dom";
import CustomLink from "../../formUI/CustomLink";
import ServerPaginationDataGrid from "../ServerPaginationDataGrid";
import RestoListFilter from "../filters/RestoListFilter";
import touchMp3 from "../../../assets/images/touch.mp3";
import { removeRealTimeOrder } from "../../../Redux Store/Slices/realtimeOrder";
import CustomButton from "../../formUI/CustomButton";
import CustomDialog from "../../layoutUI/CustomDialog";
import { ArrowBack, Close } from "@mui/icons-material";
import axios from "axios";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  FormLabel,
} from "@mui/material";
import LocalMallOutlinedIcon from "@mui/icons-material/LocalMallOutlined";
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import ReceiptIcon from '@material-ui/icons/Receipt';

const PendingOrders = ({ getAllOrderDetails }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { orderUpcomingGetUrl, getPendingOrders, itemGetUrl } = useGetApis();
  const navigate = useNavigate();

  const [search, setSearch] = useState("");
  const [search1, setSearch1] = useState("");
  const [loading,setLoading] = useState(false);
  const [type, setType] = useState("");
  const [status, setStatus] = useState("");
  const [orderId, setOrderId] = useState([]);
  const [newRowArray, setNewRowArray] = useState([]);
  const [orderData, setOrderData] = useState([]);
  const [outletId, setOutletId] = useState(user?.outletId);
  const [sortType, setSortType] = useState("");
  const [orderOpen, setOrderOpen] = useState(false);
  const [orderDetail, setOrderDetail] = useState(null);
  const [openDetail, setOpenDetail] = useState(false);
  const [quantities, setQuantities] = useState({});

  const audioRef = useRef(new Audio(touchMp3));
  const outletIdNew = useSelector((state) => state.outledId.outletId);
  const dummyRows = useSelector((state) => state.pendingOrders.pendingOrders);
  const pendingTOCancelOrders = useSelector(
    (state) => state.pendingOrders.pendingTOCancelOrders
  );

  const [orderType, setOrderType] = useState("Dine In");

  const handleOrderTypeChange = (event) => {
    setOrderType(event.target.value);
  };

  // console.log("pending ", dummyRows);

  // useEffect(() => {
  //   const eventSource = new EventSource(
  //     "https://mealpe-testing-api.onrender.com/order/order/realtimePendingOrder/" + outletId
  //   );
  //   eventSource.onopen = (e) => {
  //
  //   };
  //   eventSource.onmessage = (e) => {
  //
  //   };
  //   eventSource.addEventListener("neworder", (e) => {
  //
  //     audioRef.current.play();
  //
  //     setNewRowArray([JSON.parse(e?.data), ...newRowArray]);
  //   });

  //   return () => {
  //     eventSource.close();
  //   };
  // }, [newRowArray]);

  const [chipColor] = useState({
    accepted: {
      backgroundColor: "#F0FDF4",
      textColor: "#15803D",
    },
    pending: {
      backgroundColor: "#FFFBEB",
      textColor: "#B45309",
    },
    pickUp: {
      backgroundColor: "#FFFBEB",
      textColor: "#B45309",
    },
    cancelled: {
      backgroundColor: "#FEF2F2",
      textColor: "#B91C1C",
    },
    ord: {
      backgroundColor: "#EEF2FF",
      textColor: "#4338CA",
    },
    cancelled: {
      backgroundColor: "#FEF2F2",
      textColor: "#B91C1C",
    },
    foodready: {
      backgroundColor: "#FDF5FF",
      textColor: "#7E22CE",
    },
    delivered: {
      backgroundColor: "#F0FDF4",
      textColor: "#15803D",
    },
    dispatch: {
      backgroundColor: "#F0F6FF",
      textColor: "#1347CC",
    },
    dineIn: {
      backgroundColor: "#F0F6FF",
      textColor: "#1347CC",
    },
    restaurants: {
      backgroundColor: "#F1F5F9",
      textColor: "#1347CC",
    },
  });

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (!document.hidden) {
        setOrderData((prevData) => [...prevData]);
      }
    }, 30000); // 30 sec

    return () => clearInterval(intervalId);
  }, []);

  const orderDetails = (_orderId) => {
    navigate(`/orders/${_orderId}`);
  };
  const handleAccept = (_orderId) => {
    return acceptOrder(_orderId)
      .then((res) => {
        setOrderId((p) => [...p, _orderId]);
        getAllOrderDetails();
        dispatch(removeRealTimeOrder(_orderId));
      })
      .catch((error) => {});
  };
  const handleReject = (_orderId) => {
    return rejectOrder(_orderId)
      .then((res) => {
        setOrderId((p) => [...p, _orderId]);
        getAllOrderDetails();
        dispatch(removeRealTimeOrder(_orderId));
      })
      .catch((error) => {});
  };

  const normalizeString = (str) =>
    str?.trim()?.toLowerCase()?.replace(/\s+/g, " ");
  const getFilterData1 = (data) => {
    let _data = data;
    if (search1) {
      const searchTerm = normalizeString(search1);
      _data = _data?.filter((row) =>
        normalizeString(row?.itemname)?.includes(searchTerm)
      );
    }
    return _data;
  };

  const getFilterData = (data) => {
    let _data = [...newRowArray, ...data];
    if (type)
      _data = _data?.filter((row) =>
        type == "Dining"
          ? row.is_dine_in
          : type == "Pickup"
          ? row.is_pick_up
          : type == "Delivery"
          ? row.is_delivery
          : true
      );
    if (sortType == "Ascending") {
      _data = [..._data].sort((a, b) => {
        const dateTimeA = new Date(
          `${a.order_schedule_date}T${a.order_schedule_time}`
        );
        const dateTimeB = new Date(
          `${b.order_schedule_date}T${b.order_schedule_time}`
        );
        return dateTimeA - dateTimeB;
      });
    }
    if (sortType == "Descending") {
      _data = [..._data].sort((a, b) => {
        const dateTimeA = new Date(
          `${a.order_schedule_date}T${a.order_schedule_time}`
        );
        const dateTimeB = new Date(
          `${b.order_schedule_date}T${b.order_schedule_time}`
        );
        return dateTimeB - dateTimeA;
      });
    }
    if (search)
      _data = _data?.filter((row) => row.order_sequence_id.includes(search));
    if (orderId.length)
      _data = _data?.filter((o) => !orderId.includes(o.order_id));
    // if (newRow) {
    //   _data.unshift(newRow);
    //
    // }
    // console.clear()

    // return _data
    return _data;
  };

  const columns = useMemo(() => [
    {
      field: "order_id",
      headerName: "Order ID",
      flex: 1,
      minWidth: 90,
      maxWidth: 130,
      sortable: false,
      renderCell: ({ row }) => {
        //
        return <Text bold>{row?.order_sequence_id}</Text>;
      },
    },
    // {
    //   field: "customer",
    //   headerName: "Customer",
    //   flex: 1,
    //   minWidth: 180,
    //   sortable: false,
    //   renderCell: ({ row }) => (
    //     <ItemCard
    //       imgSx={{
    //         borderRadius: "50%",
    //       }}
    //       img={row?.photo}
    //       title={row?.customer_name}
    //       subTitle={`+ ${row?.mobile}`}
    //       center
    //       sx={{
    //         height: 36,
    //       }}
    //     />
    //   ),
    // },
    {
      field: "item",
      headerName: "Order Details",
      flex: 1,
      minWidth: 240,
      sortable: false,
      renderCell: ({ row }) => <ItemDetails menuItem={row?.menu_item} />,
    },
    {
      field: "actions",
      headerName: "Order Accept Reject",
      flex: 1,
      minWidth: 170,
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <OrderStatus
            row={row}
            chipColor={chipColor}
            handleAccept={handleAccept}
            handleReject={handleReject}
            title="Accept"
            noChip
            button
          />
        );
      },
    },
    {
      field: "view",
      headerName: "Action",
      flex: 1,
      minWidth: 110,
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <CustomLink
            onClick={() => orderDetails(row?.order_id)}
            sx={{
              cursor: "pointer",
            }}
          >
            View Details
          </CustomLink>
        );
      },
    },
    {
      field: "order_type",
      headerName: "Order Type",
      flex: 1,
      minWidth: 120,
      maxWidth: 250,
      sortable: false,
      renderCell: ({ row }) => {
        let label, type;

        if (row?.is_dine_in) {
          label = "Dine In";
          type = "dineIn";
        } else if (row?.is_pick_up) {
          label = "Pick Up";
          type = "pickUp";
        } else if (row?.is_delivery) {
          label = "Delivery";
          type = "foodready";
        } else {
          label = "Unknown";
          type = "Unknown";
        }

        return (
          <div>
            <CustomChip
              label={label}
              textColor={chipColor[type]?.textColor}
              backgroundColor={chipColor[type]?.backgroundColor}
            />
            {row?.is_delivery && row?.delivery_addresss && (
              <div
                style={{
                  marginTop: "5px",
                  fontSize: "1rem",
                  fontWeight: "600",
                }}
              >
                {row.delivery_addresss}
              </div>
            )}
          </div>
        );
      },
    },
    {
      field: "schedule_type",
      headerName: "Schedule Type",
      flex: 1,
      minWidth: 130,
      sortable: false,
      renderCell: ({ row }) => (
        <CustomChip
          label={row?.schedule_now ? "Now" : "Later"}
          textColor={
            chipColor[row?.schedule_now ? "dineIn" : "pending"]?.textColor
          }
          backgroundColor={
            chipColor[row?.schedule_now ? "dineIn" : "pending"]?.backgroundColor
          }
        />
      ),
    },
    // {
    //   field: "status",
    //   headerName: "Status",
    //   flex: 1,
    //   minWidth: 180,
    //   sortable: false,
    //   renderCell: ({ row }) => (
    //     <CustomChip
    //       label={row.orderId.orderStatusId.text}
    //       textColor={
    //         chipColor[row.orderId.orderStatusId.text.toLowerCase()]?.textColor
    //       }
    //       backgroundColor={
    //         chipColor[row.orderId.orderStatusId.text.toLowerCase()]
    //           ?.backgroundColor
    //       }
    //     />
    //   ),
    // },
    {
      field: "order_value",
      headerName: "Order Value",
      flex: 1,
      minWidth: 120,
      sortable: false,
      renderCell: ({ row }) => (
        <>
          {" "}
          <Text bold>₹ {row?.total_price?.toFixed(2)}</Text>
        </>
      ),
    },
    {
      field: "date and time",
      headerName: "Delivery Time",
      flex: 1,
      minWidth: 180,
      sortable: false,
      renderCell: ({ row }) => {
        const parsedTime = moment(row?.order_schedule_time, "HH:mm:ss");
        const formattedTime = parsedTime.format("h:mm A");

        const parsedDate2 = moment(row?.order_schedule_date, "YYYY-MM-DD");
        let formattedAdjustedTime;

        if (row?.schedule_now) {
          const preparation_time = row?.preparation_time || 0;
          const adjustedTime = parsedTime.add(preparation_time + 15, "minutes");

          // Check if the adjusted time is past 12 AM
          // if (adjustedTime.isAfter(moment('12:00 AM', "h:mm A"))) {
          //   parsedDate2.add(1, 'day'); // Add 1 day to the date
          // }

          formattedAdjustedTime = adjustedTime.format("h:mm A");
        }

        const formattedDate2 = parsedDate2.format("ddd, DD MMM YYYY");

        return (
          <Stack>
            <Text bold>{formattedDate2}</Text>
            <Text bold>
              {row?.schedule_now ? formattedAdjustedTime : formattedTime}
            </Text>
          </Stack>
        );
      },
    },
  ]);

  const handleQuantityChange = (itemid, change) => {
    setQuantities((prevQuantities) => {
      const newQuantity = (prevQuantities[itemid] || 0) + change;
      return {
        ...prevQuantities,
        [itemid]: Math.max(newQuantity, 0), // Ensure quantity doesn't go below 0
      };
    });
  };

  // console.log("items ", quantities);

  const columns1 = useMemo(() => [
    {
      field: "itemid",
      headerName: "ID",
      flex: 1,
      maxWidth: 50,
      sortable: false,
      renderCell: ({ row }) => <Text bold>{row?.itemSequenceId}</Text>,
    },
    {
      field: "itemname",
      headerName: "Name",
      flex: 1,
      minWidth: 250,
      sortable: false,
      renderCell: ({ row }) => (
        <ItemCard
          imgSx={{
            borderRadius: "8px",
            objectFit: "cover",
          }}
          img={row.item_image_url}
          sx={{
            height: 36,
          }}
          title={row.itemname}
          starCount={4}
        />
      ),
    },
    {
      field: "price",
      headerName: "Price",
      flex: 1,
      minWidth: 100,
      sortable: false,
      renderCell: ({ row }) => <Text bold>₹ {row?.price}</Text>,
    },
    {
      field: "quantity",
      headerName: "Qty",
      flex: 1,
      minWidth: 100,
      sortable: false,
      renderCell: ({ row }) => (
        <Box style={{ display: "flex", alignItems: "center" }}>
          <button
            variant="outlined"
            style={{
              background: "white",
              border: "1.3px solid #4F46E5",
              borderRadius: "0.4rem",
              cursor: "pointer",
            }}
            onClick={() => handleQuantityChange(row?.itemid, -1)}
          >
            -
          </button>
          <Text style={{ margin: "0 10px" }}>
            {quantities[row?.itemid] || 0}
          </Text>
          <button
            variant="outlined"
            style={{
              background: "white",
              border: "1.3px solid #4F46E5",
              borderRadius: "0.4rem",
              cursor: "pointer",
            }}
            onClick={() => handleQuantityChange(row?.itemid, 1)}
          >
            +
          </button>
        </Box>
      ),
    },
  ]);

  const handleSeeOrder = async () => {
    setOrderOpen(false);
    try {
      const orderData = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}outlet/menu/getDetailsForItems`,
        {
          items: quantities,
          outletId: outletIdNew,
        }
      );

      // console.log("orderData ", orderData);

      if (orderData?.data?.success) {
        setOrderDetail(orderData?.data);
        setOpenDetail(true);
      }
    } catch (error) {
      console.log("Error while fetch order data");
    }
  };

  const handlePlaceOrder = async () => {
    setLoading(true);
    try {
      // console.log("order ", orderDetail);

      const order = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}payment/customer/getTransactionIdForCOD`,
        {
          itemTotalPrice: orderDetail?.totalAmount,
          productinfo: "items",
          firstname: "Restaurant",
          phone: "9638819704",
          email: "gajerajaymin5@gmail.com",
          customerAuthUID: "f35229d5-3ecc-41de-80d2-0e9615a24b88",
          outletId: outletIdNew,
          isDelivery: orderType == "Delivery" ? true : false,
          isDineIn: orderType == "Dine In" ? true : false,
          isPickUp: orderType == "Take Away" ? true : false,
        }
      );

      // console.log("orderData ", order);

      if (order?.data?.success) {
        const orderDaata = await axios.post(
          `${process.env.REACT_APP_BACKEND_BASE_URL}order/order/createOrder`,
          {
            additional_Instruction: "",
            address: "",
            basePrice: order?.data?.response?.foodBasePrice,
            customerAuthUID: order?.data?.response?.customerAuthUID,
            outletId: outletIdNew,
            paymentId: "",
            isCashOrder: true,
            isDelivery: orderType == "Delivery" ? true : false,
            isDineIn: orderType == "Dine In" ? true : false,
            isPickUp: orderType == "Take Away" ? true : false,
            isScheduleNow: true,
            pickupTime: {
              orderDate: new Date()?.toISOString().split("T")[0],
              time: new Date()?.toTimeString().split(" ")[0],
            },
            items: orderDetail?.data,
            restaurantId: null,
            totalPrice: order?.data?.response?.amount,
            txnid: order?.data?.response?.txnid,
          }
        );

        // console.log("place order ", orderDaata);

        if (orderDaata?.data?.success) {
          setOrderOpen(false);
          setOpenDetail(false);
          setQuantities({});
          const orderrr = await axios.post(
            `${process.env.REACT_APP_BACKEND_BASE_URL}order/order/sendOrderToHistory`,
            {
              orderId: orderDaata?.data?.data?.orderid,
            }
          );

          // console.log("send roder to history ", orderrr);

          if(orderrr?.data?.success){
            setOrderOpen(false);
            setOpenDetail(false);
            setQuantities({});

            navigate("/orders/#history");
            window?.location?.reload();
          }
        }
      }
    } catch (error) {
      console.log("Error while create order");
    }finally{
      setLoading(false);
    }
  };

  return (
    <Box p={0}>
      <PageHeader
        secondary
        title="Pending Orders"
        subTitle={"Deliver these orders within 2 hours."}
      />
      <StackRow between mb={"24px"}>
        <CustomSearchTextField
          placeholder="Search by Order ID"
          value={search}
          onChange={(e) => setSearch(e.target.value.replace(/[^0-9]/g, ""))}
        />
        <StackRow gap="20px">
          <StatusFilter
            label="Sort By Time"
            menuList={[
              { id: 1, label: "Ascending" },
              { id: 2, label: "Descending" },
            ]}
            onClick={(label) => setSortType(label)}
          />
          <StatusFilter
            label="Order Type"
            defaultValue={"All"}
            menuList={[
              { id: null, label: "All" },
              { id: 1, label: "Dining" },
              { id: 2, label: "Pickup" },
              { id: 3, label: "Delivery" },
            ]}
            onClick={(label) => setType(label)}
          />
          <CustomButton onClick={() => setOrderOpen(true)}>
            <AddCircleOutlineIcon style={{ marginRight: "0.5rem" }} /> Take
            Order
          </CustomButton>
        </StackRow>
      </StackRow>
      <CustomDataGrid
        // dummyRows={dummyRows}
        dummyRows={dummyRows?.filter(
          (f) => !pendingTOCancelOrders?.some((s) => s?.orderId === f?.order_id)
        )}
        name="pending_orders"
        rowId={"order_id"}
        // url={getPendingOrders + user?.outletId}
        columns={columns}
        labelName="Pending Order"
        filterFn={getFilterData}
        noActions
        getRowHeight={() => "auto"}
        // getEstimatedRowHeight={() => 300}
      />

      {orderOpen && (
        <CustomDialog dontClose open={orderOpen} sx={{ width: "80rem" }}>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <PageHeader secondary title={`Order Items`} />
            <Close
              style={{ cursor: "pointer", fontSize: "2rem" }}
              onClick={() => setOrderOpen(false)}
            />
          </Box>

          {/* <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          > */}

          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <CustomSearchTextField
              style={{ marginBottom: "1rem" }}
              placeholder="Search"
              value={search1}
              onChange={(e) => setSearch1(e.target.value)}
            />
            {Object.values(quantities).some((quantity) => quantity > 0) && (
              <Button
                style={{
                  fontSize: "1.1rem",
                  backgroundColor: "#574eed",
                  color: "white",
                  marginBottom: "1rem",
                  display:"flex",
                  alignItems:"center"
                }}
                sx={{ textTransform: "none" }}
                onClick={handleSeeOrder}
              >
                <LocalMallOutlinedIcon
                  style={{ fontSize: "1.9rem", marginRight: "0.5rem" }}
                />{" "}
             <div>
             Check Out (
                {Object.values(quantities).reduce(
                  (acc, res) => Number(acc) + Number(res),
                  0
                )}
                )
             </div>
              </Button>
            )}
          </Box>
          <CustomDataGrid
            rowId="itemid"
            name="item-table"
            height={"490px"}
            columns={columns1}
            url={itemGetUrl + `${outletIdNew}`}
            labelName={"Items"}
            noActions
            filterFn={getFilterData1}
          />
          {/* </Box> */}
        </CustomDialog>
      )}

      {openDetail && (
        <CustomDialog dontClose open={openDetail} sx={{ width: "80rem" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "1rem",
                marginTop: "-3.5rem",
                fontSize: "2rem",
                cursor: "pointer",
              }}
            >
              <ArrowBack style={{fontSize:"2rem"}} onClick={() => setOrderOpen(true)} />
            </Box>

            <PageHeader secondary title="Order Details" />

            <Box
              style={{
                display: "flex",
                gap: "0.5rem",
                justifyContent: "flex-end",
                marginTop: "-2.5rem",
                marginRight: "1rem",
              }}
            >
              <Text style={{ fontWeight: "500", fontSize: "1.3rem" }}>
                Items
              </Text>
              <Text style={{ fontWeight: "500", fontSize: "1.3rem" }}>
                (
                {Object.values(quantities).reduce(
                  (acc, res) => Number(acc) + Number(res),
                  0
                )}
                )
              </Text>
            </Box>
          </Box>

          <Box style={{ padding: "1.5rem" }}>
            {orderDetail?.data?.map((order) => (
              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "1rem",
                }}
              >
                <Text>
                  {order?.itemname} x {order?.qty}
                </Text>
                <Text>₹{order?.price.toFixed(2)}</Text>
              </Box>
            ))}
            <Divider />
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "1rem",
              }}
            >
              <Text>Delivery Charge:</Text>
              <Text>₹{orderDetail?.taxPrice?.deliveryCharge?.toFixed(2)}</Text>
            </Box>
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "1rem",
              }}
            >
              <Text>Packaging Charge:</Text>
              <Text>₹{orderDetail?.taxPrice?.packagingCharge?.toFixed(2)}</Text>
            </Box>
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "1rem",
                marginBottom: "1rem",
              }}
            >
              <Text>Convenience Fee:</Text>
              <Text>
                ₹{orderDetail?.taxPrice?.convenienceAmount?.toFixed(2)}
              </Text>
            </Box>
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "1rem",
              }}
            >
              <Text bold>Sub + Taxes:</Text>
              <Text bold>
                ₹{orderDetail?.taxPrice?.totalPriceForCustomer?.toFixed(2)}
              </Text>
            </Box>
          </Box>

          {/* Radio Buttons for Order Type */}
          <Box style={{ padding: "1.5rem" }}>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="orderType"
                name="orderType"
                value={orderType}
                onChange={handleOrderTypeChange}
                row // Makes the radio buttons display in a row
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: "1rem",
                  }}
                >
                  <Box
                    sx={{
                      border: "1px solid #4F46E5",
                      borderRadius: "0.5rem",
                      padding: "0.3rem 1rem",
                    }}
                  >
                    <FormControlLabel
                      value="Take Away"
                      control={<Radio />}
                      label="Take Away"
                    />
                  </Box>

                  <Box
                    sx={{
                      border: "1px solid #4F46E5",
                      borderRadius: "0.5rem",
                      padding: "0.3rem 1rem",
                    }}
                  >
                    <FormControlLabel
                      value="Delivery"
                      control={<Radio />}
                      label="Delivery"
                    />
                  </Box>

                  <Box
                    sx={{
                      border: "1px solid #4F46E5",
                      borderRadius: "0.5rem",
                      padding: "0.3rem 1rem",
                    }}
                  >
                    <FormControlLabel
                      value="Dine In"
                      control={<Radio />}
                      label="Dine In"
                    />
                  </Box>
                </Box>
              </RadioGroup>
            </FormControl>
          </Box>

          <Box
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "1.4rem",
              gap: "1rem",
            }}
          >
            <Button
              style={{
                backgroundColor: "#F9F7F5",
                color: "black",
                fontSize: "1rem",
              }}
              onClick={() => {
                setOpenDetail(false);
                setQuantities({});
              }}
              sx={{ textTransform: "none" }}
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              style={{
                fontSize: "1rem",
                textDecoration: "underline",
              }}
              onClick={() => {
                setOrderOpen(true);
              }}
              sx={{ textTransform: "none" }}
            >
              Edit
            </Button>
            <Button
              style={{
                fontSize: "1rem",
                backgroundColor: "#574eed",
                color: "white",
              }}
              sx={{ textTransform: "none" }}
              onClick={handlePlaceOrder}
            ><ReceiptIcon style={{ fontSize: "1.8rem", marginRight: "0.5rem" }}/>
              {loading ? "Ordering..." : "Place Order"}
            </Button>
          </Box>
        </CustomDialog>
      )}
    </Box>
  );
};

export default PendingOrders;

const ItemDetails = ({ menuItem }) => {
  const order = menuItem?.slice(0, 4).map((item, index) => (
    <React.Fragment key={item?.item_price * index}>
      <Text
        bold
        sx={{
          overflow: "hidden",
          width: "230px",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
        }}
      >
        {item?.quantity} x {item?.itemname}{" "}
        {item?.variation?.variation_name ? item.variation.variation_name : ""}
        {item?.addons?.length
          ? ", " + item?.addons.map((addon) => addon?.name).join(", ")
          : ""}
      </Text>
    </React.Fragment>
  ));
  return (
    <span>
      {order}{" "}
      {menuItem?.length > 4 && (
        <Chip
          label={"+" + (menuItem?.length - 4) + " more"}
          sx={{ height: "28px", py: "8px" }}
        />
      )}
    </span>
  );
};
